import { QueryClient } from "@tanstack/react-query";
import orderBy from "lodash/orderBy";
import { GetStaticProps } from "next";
import { getPageStaticProps } from "pages/[slug]";
import { getPagesOfType } from "src/lib/api";
import findAndPopulateCollection from "src/lib/api-util/FindAndPopulateCollection";
import getFooterSection from "src/services/contentful/footer/getFooterMenu";
import { getHeaderMenuGraphQL } from "src/services/contentful/mainHeader/getMainHeader";
import getPageEntries from "src/services/contentful/page/getPageEntries";
import generateRssFeed from "src/services/contentful/post/generateRssFeed";
import getBlogIndexEntry from "src/services/contentful/post/getBlogIndexEntry";
import getPostEntries from "src/services/contentful/post/getPostEntries";
import { getSiteSettingsGql } from "src/services/contentful/settings/settings";
import getTags from "src/services/contentful/tag/getTags";

export const BLOG_PAGE_SIZE = 20;

export { default } from "src/pages/page";

export const getStaticProps: GetStaticProps = async (data) => {
  const { params, preview = false } = data;
  let oldPostsAndNewPages: any = [];
  try {
    const blogPages = await getPagesOfType({
      pageContentType: "pageBlogPost",
      query: {
        order: "-fields.pubDate",
      },
    });

    const queryClient = new QueryClient();

    const settings = await getSiteSettingsGql({
      queryClient,
      preview,
    });

    // order blog pages because the query above doesn't work
    orderBy(blogPages, "fields.content.fields.date");

    // let blogPages: any = await getPageEntries({
    //   query: {
    //     //order: "-fields.date",
    //     limit: BLOG_PAGE_SIZE,
    //     //"fields.content.sys.contentType.sys.id": "pageBlogPost",
    //     //select: "sys,fields,metadata",
    //   },
    //   includeContent: true,
    //   preview,
    //   contentType: "page",
    // });
    const oldPosts = await getPostEntries({
      preview,
      query: {
        order: "-fields.date",
        limit: BLOG_PAGE_SIZE,
      },
    });

    let postsCollection = [...oldPosts, ...blogPages];

    postsCollection = postsCollection.sort((a: any, b: any) => {
      const aDate = a.date ? a?.date : a?.fields?.pubDate || a?.sys?.createdAt;
      const bDate = b.date ? b?.date : b?.fields?.pubDate || b?.sys?.createdAt;

      return new Date(bDate).getTime() - new Date(aDate).getTime();
    });

    // Builds the RSS xml feed
    await generateRssFeed({ oldPosts, blogPages });

    let headergql = await getHeaderMenuGraphQL({
      preview: preview || false,
    });

    // let featuredPost = await getPagesOfType({
    //   pageContentType: "page",
    //   subPageContentType: "pageBlogPost",
    //   query: {
    //     limit: 1,
    //     "fields.content.fields.featured": true,
    //   },
    // });

    // if (!featuredPost) {
    //   featuredPost = await getPostEntries({
    //     preview,
    //     query: {
    //       limit: 1,
    //       order: "-fields.date",
    //       "fields.featured": true,
    //     },
    //   });
    // }

    const blogIndexEntry = await getBlogIndexEntry({
      preview,
      includeContent: true,
      query: {
        limit: BLOG_PAGE_SIZE,
      },
    });

    //TODO: get rid of the side effect
    findAndPopulateCollection(blogIndexEntry, "Blog Posts", postsCollection);

    const tags = await getTags(preview);

    let footerMenu = await getFooterSection({
      query: {
        limit: 1,
        include: 5,
        select: "sys.id,sys.contentType,sys.type,fields",
      },
      preview: preview || false,
    });

    return {
      props: {
        settings,
        preview,
        collection: postsCollection,
        page: blogIndexEntry,
        //featuredPost: featuredPost[0] ?? null,
        tags,
        footerMenu,
        header: headergql,
      },
    };
  } catch (e) {
    console.error(e);
  }
  return {
    props: {},
  };
};
